import React from 'react';
import PdfGenerator from '../components/PdfGenerator';

const PdfTest: React.FC = () => {
  return (
    <div className="pdf-test-page" style={{ padding: '20px' }}>
      <h1>PDF Generation Test Page</h1>
      <p>
        This page demonstrates the generation of a PDF with Hebrew text using the pdfmake library.
        The generated PDF will contain the text "שלום עולם" (Hello World in Hebrew) and will be
        saved to the src/pdfs folder.
      </p>
      <div style={{ marginTop: '30px' }}>
        <PdfGenerator />
      </div>
    </div>
  );
};

export default PdfTest;
