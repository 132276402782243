import React, { useEffect, useState } from 'react';
import './PrintNotification.css';

export type PrintJobType = 'single' | 'order' | 'order_txt' | 'batch';

interface PrintNotificationProps {
  isVisible: boolean;
  onClose: () => void;
  jobType: PrintJobType;
  queueId?: number;
  itemCount?: number;
  autoHideDelay?: number; // in milliseconds
}

const PrintNotification: React.FC<PrintNotificationProps> = ({
  isVisible,
  onClose,
  jobType,
  queueId,
  itemCount = 1,
  autoHideDelay = 3000,
}) => {
  const [animationState, setAnimationState] = useState<'hidden' | 'sliding-up' | 'visible' | 'sliding-down'>('hidden');

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (isVisible && animationState === 'hidden') {
      // Start sliding up
      setAnimationState('sliding-up');
      
      // After animation completes, set to visible
      timeoutId = setTimeout(() => {
        setAnimationState('visible');
        
        // Auto-hide after delay
        timeoutId = setTimeout(() => {
          setAnimationState('sliding-down');
          
          // After sliding down animation completes, call onClose
          timeoutId = setTimeout(() => {
            setAnimationState('hidden');
            onClose();
          }, 300); // Match the CSS transition duration
        }, autoHideDelay);
      }, 300); // Match the CSS transition duration
    } else if (!isVisible && animationState !== 'hidden') {
      // If manually closed, start sliding down
      setAnimationState('sliding-down');
      
      // After sliding down animation completes, set to hidden
      timeoutId = setTimeout(() => {
        setAnimationState('hidden');
      }, 300); // Match the CSS transition duration
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [isVisible, animationState, autoHideDelay, onClose]);

  const getMessage = () => {
    switch (jobType) {
      case 'single':
        return 'Image sent to print queue';
      case 'order':
        return `${itemCount} image${itemCount !== 1 ? 's' : ''} sent to print queue`;
      case 'order_txt':
        return 'Order details sent to print queue';
      case 'batch':
        return `${itemCount} order${itemCount !== 1 ? 's' : ''} sent to print queue`;
      default:
        return 'Item sent to print queue';
    }
  };

  const getIcon = () => {
    switch (jobType) {
      case 'single':
      case 'order':
        return '🖼️';
      case 'order_txt':
        return '📄';
      case 'batch':
        return '📦';
      default:
        return '🖨️';
    }
  };

  if (animationState === 'hidden') {
    return null;
  }

  return (
    <div className={`print-notification ${animationState}`}>
      <div className="print-notification-content">
        <div className="print-notification-icon">{getIcon()}</div>
        <div className="print-notification-message">
          <div className="print-notification-title">{getMessage()}</div>
          {queueId && (
            <div className="print-notification-queue-id">Queue ID: {queueId}</div>
          )}
        </div>
        <button className="print-notification-close" onClick={onClose}>
          ×
        </button>
      </div>
    </div>
  );
};

export default PrintNotification;
