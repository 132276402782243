// File: src/pages/MobileDashboard.tsx

import React, { useState, useEffect, useMemo } from 'react';
import MobileOrderList from '../components/Orders/MobileOrderList';
import { fetchOrders } from '../api/googleSheets';
import Sidebar from '../components/Layout/Sidebar';
import SalesGraph from '../components/Dashboard/SalesGraph';
import BatchPrintModal from '../components/Orders/BatchPrintModal';
import './MobileDashboard.css';
import { useAuth0 } from '@auth0/auth0-react';

const MobileDashboard: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [orders, setOrders] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [filter, setFilter] = useState('All');
  const [searchTerm, setSearchTerm] = useState('');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isBatchPrintModalOpen, setIsBatchPrintModalOpen] = useState(false);
  
  const statusCounts = useMemo(() => {
    return orders.reduce((acc, order) => {
      acc[order.status] = (acc[order.status] || 0) + 1;
      return acc;
    }, {} as Record<string, number>);
  }, [orders]);

  useEffect(() => {
    const loadOrders = async () => {
      try {
        setLoading(true);
        const accessToken = await getAccessTokenSilently();
        const data = await fetchOrders(accessToken);
        setOrders(data);
      } catch (err) {
        if (err instanceof Error) {
          setError(`Failed to load orders: ${err.message}`);
        } else {
          setError('An unknown error occurred while loading orders');
        }
        console.error('Error loading orders:', err);
      } finally {
        setLoading(false);
      }
    };
  
    loadOrders();
  }, [getAccessTokenSilently]);

  // Helper function to parse dates
  const parseDate = (dateStr: string) => {
    const [day, month, year] = dateStr.split('/');
    // Ensure padding of single digits with leading zeros
    const paddedMonth = month.padStart(2, '0');
    const paddedDay = day.padStart(2, '0');
    // Create date using a more reliable format
    return new Date(Number(year), Number(month) - 1, Number(day));
  };

  const validOrders = useMemo(
    () =>
      orders
        .filter(order => /^\d+$/.test(order.orderId))
        .map(order => {
          // Parse date
          const dateObject = parseDate(order.date);
          // Parse price and frame count
          const priceNumber = parseFloat(order.price) || 0;
          const frameCount = parseInt(order.items) || 0;

          return {
            ...order,
            dateObject,
            priceNumber,
            frameCount,
          };
        }),
    [orders]
  );

  const filteredOrders = useMemo(() => {
    return validOrders.filter(order => {
      const matchesStatus = filter === 'All' || order.status === filter;
      const matchesSearch =
        searchTerm === '' ||
        order.orderId.toLowerCase().includes(searchTerm.toLowerCase()) ||
        order.customer.toLowerCase().includes(searchTerm.toLowerCase());
      return matchesStatus && matchesSearch;
    });
  }, [validOrders, filter, searchTerm]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      setIsSidebarOpen(false);
    }
  };

  const handleBatchPrint = () => {
    setIsBatchPrintModalOpen(true);
  };

  if (loading) return <div className="loading">Loading...</div>;
  if (error) return <div className="error">Error: {error}</div>;

  return (
    <div className="mobile-dashboard">
      {isSidebarOpen && (
        <div className="sidebar-overlay" onClick={closeSidebar}>
          <div className="sidebar-container">
            <Sidebar />
          </div>
        </div>
      )}
      <SalesGraph orders={validOrders} />
      <div className="mobile-controls">
        <div className="mobile-search">
          <input
            type="text"
            placeholder="Search orders..."
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
          />
        </div>
        <button 
          className="mobile-batch-print-button"
          onClick={handleBatchPrint}
        >
          Batch Print
        </button>
      </div>
      <div className="mobile-filter-tabs">
        <button
          className={`filter-tab ${filter === 'All' ? 'active' : ''}`}
          onClick={() => setFilter('All')}
        >
          All
        </button>
        <button
          className={`filter-tab ${filter === 'נקלט' ? 'active' : ''}`}
          onClick={() => setFilter('נקלט')}
        >
          נקלט ({statusCounts['נקלט'] || 0})
        </button>
        <button
          className={`filter-tab ${filter === 'הודפס' ? 'active' : ''}`}
          onClick={() => setFilter('הודפס')}
        >
          הודפס ({statusCounts['הודפס'] || 0})
        </button>
        <button
          className={`filter-tab ${filter === 'בוטל' ? 'active' : ''}`}
          onClick={() => setFilter('בוטל')}
        >
          בוטל
        </button>
        <button
          className={`filter-tab ${filter === 'מוכן' ? 'active' : ''}`}
          onClick={() => setFilter('מוכן')}
        >
          מוכן
        </button>
      </div>
      <MobileOrderList orders={filteredOrders} />
      
      <BatchPrintModal
        isOpen={isBatchPrintModalOpen}
        onRequestClose={() => {
          setIsBatchPrintModalOpen(false);
          // Reload orders to refresh status after batch printing
          const loadOrders = async () => {
            try {
              const accessToken = await getAccessTokenSilently();
              const data = await fetchOrders(accessToken);
              setOrders(data);
            } catch (err) {
              console.error('Error reloading orders:', err);
            }
          };
          loadOrders();
        }}
        orders={validOrders}
      />
    </div>
  );
};

export default MobileDashboard;
