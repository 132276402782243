import React, { useState } from 'react';
import axios from 'axios';
import { saveAs } from 'file-saver';

// API base URL
const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';

// Component for generating PDFs with Hebrew text
const PdfGenerator: React.FC = () => {
  const [isGenerating, setIsGenerating] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // Function to generate a PDF with Hebrew text using the server-side service
  const generatePdf = async () => {
    setIsGenerating(true);
    setError(null);
    
    try {
      // Sample order text with Hebrew content
      const orderText = `
(1) Order Date : ${new Date().toLocaleDateString()}
(2) Customer Name : ישראל ישראלי
(3) Phone : 050-1234567
(4) Email : israel@example.com
(5) Address : רחוב הרצל 1, תל אביב
(6) Order Type : Standard
(7) Delivery Method : Pickup
(8) Payment Method : Credit Card
(9) Total Amount : ₪150.00
(10) Status : New
(11) Order ID : ORD-${Date.now().toString().substring(6)}
(12) Notes : שלום עולם! זוהי הערה בעברית.
`;

      // Get the access token (if using authentication)
      // If you're not using Auth0 or another auth provider, you'll need to modify this
      let accessToken = '';
      try {
        // @ts-ignore - Assuming Auth0 is available in the global scope
        if (window.auth0 && window.auth0.getAccessTokenSilently) {
          // @ts-ignore
          accessToken = await window.auth0.getAccessTokenSilently();
        }
      } catch (authError) {
        console.error('Error getting access token:', authError);
        // Continue without token - the API might allow public access or handle this case
      }

      // Call the server API to generate the PDF
      const response = await axios({
        method: 'post',
        url: `${API_BASE_URL}/pdf/generate-pdf`,
        data: { orderText },
        responseType: 'blob',
        headers: accessToken ? { Authorization: `Bearer ${accessToken}` } : {},
      });
      
      // Create a blob from the PDF data
      const blob = new Blob([response.data], { type: 'application/pdf' });
      
      // Create a filename with timestamp
      const fileName = `order_hebrew_${Date.now()}.pdf`;
      
      // Use file-saver to save the PDF
      saveAs(blob, fileName);
      
    } catch (error) {
      console.error('Error generating PDF:', error);
      setError('Failed to generate PDF. Please try again.');
    } finally {
      setIsGenerating(false);
    }
  };

  // Function to print the PDF directly to the printer
  const printPdf = async () => {
    setIsGenerating(true);
    setError(null);
    
    try {
      // Sample order text with Hebrew content
      const orderText = `
(1) Order Date : ${new Date().toLocaleDateString()}
(2) Customer Name : ישראל ישראלי
(3) Phone : 050-1234567
(4) Email : israel@example.com
(5) Address : רחוב הרצל 1, תל אביב
(6) Order Type : Standard
(7) Delivery Method : Pickup
(8) Payment Method : Credit Card
(9) Total Amount : ₪150.00
(10) Status : New
(11) Order ID : ORD-${Date.now().toString().substring(6)}
(12) Notes : שלום עולם! זוהי הערה בעברית.
`;

      // Get the access token (if using authentication)
      let accessToken = '';
      try {
        // @ts-ignore - Assuming Auth0 is available in the global scope
        if (window.auth0 && window.auth0.getAccessTokenSilently) {
          // @ts-ignore
          accessToken = await window.auth0.getAccessTokenSilently();
        }
      } catch (authError) {
        console.error('Error getting access token:', authError);
        // Continue without token - the API might allow public access or handle this case
      }

      // Call the server API to print the PDF
      const response = await axios({
        method: 'post',
        url: `${API_BASE_URL}/pdf/print-order`,
        data: { orderText },
        headers: accessToken ? { Authorization: `Bearer ${accessToken}` } : {},
      });
      
      alert(`Print job sent successfully! Job ID: ${response.data.jobId}`);
      
    } catch (error) {
      console.error('Error printing PDF:', error);
      setError('Failed to print PDF. Please try again.');
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <div className="pdf-generator">
      <h2>Hebrew PDF Generator</h2>
      <p>Click the buttons below to generate or print a PDF with Hebrew text</p>
      
      <div style={{ display: 'flex', gap: '10px' }}>
        <button 
          onClick={generatePdf} 
          disabled={isGenerating}
          style={{
            padding: '10px 20px',
            fontSize: '16px',
            backgroundColor: '#4CAF50',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            cursor: isGenerating ? 'not-allowed' : 'pointer',
            opacity: isGenerating ? 0.7 : 1,
          }}
        >
          {isGenerating ? 'Processing...' : 'Download Hebrew PDF'}
        </button>
        
        <button 
          onClick={printPdf} 
          disabled={isGenerating}
          style={{
            padding: '10px 20px',
            fontSize: '16px',
            backgroundColor: '#2196F3',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            cursor: isGenerating ? 'not-allowed' : 'pointer',
            opacity: isGenerating ? 0.7 : 1,
          }}
        >
          {isGenerating ? 'Processing...' : 'Print Hebrew PDF'}
        </button>
      </div>
      
      {isGenerating && (
        <p style={{ marginTop: '10px' }}>
          Processing PDF, please wait...
        </p>
      )}
      
      {error && (
        <p style={{ marginTop: '10px', color: 'red' }}>
          {error}
        </p>
      )}
      
      <div style={{ marginTop: '20px', padding: '15px', backgroundColor: '#f5f5f5', borderRadius: '4px' }}>
        <h3>Sample PDF Content:</h3>
        <pre style={{ 
          direction: 'rtl', 
          textAlign: 'right',
          fontFamily: 'Arial, sans-serif',
          whiteSpace: 'pre-wrap',
          fontSize: '14px',
          lineHeight: '1.5'
        }}>
{`(1) Order Date : ${new Date().toLocaleDateString()}
(2) Customer Name : ישראל ישראלי
(3) Phone : 050-1234567
(4) Email : israel@example.com
(5) Address : רחוב הרצל 1, תל אביב
(6) Order Type : Standard
(7) Delivery Method : Pickup
(8) Payment Method : Credit Card
(9) Total Amount : ₪150.00
(10) Status : New
(11) Order ID : ORD-${Date.now().toString().substring(6)}
(12) Notes : שלום עולם! זוהי הערה בעברית.`}
        </pre>
      </div>
    </div>
  );
};

export default PdfGenerator;
