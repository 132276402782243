import React, { useState, useEffect, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import './BatchPrintModal.css';
import { printBatchOrders } from '../../services/printingService';
import StatusDropdown from './StatusDropdown';
import { updateOrderStatus } from '../../api/googleSheets';
import PrintNotification, { PrintJobType } from './PrintNotification';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';

interface Order {
  orderId: string;
  date: string;
  customer: string;
  status: string;
  frame: string;
  items: string;
  phoneNumber: string;
  city: string;
  notes: string;
  price: string;
}

interface BatchPrintModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  orders: Order[];
}

const BatchPrintModal: React.FC<BatchPrintModalProps> = ({ isOpen, onRequestClose, orders }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [selectedOrders, setSelectedOrders] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [printing, setPrinting] = useState(false);
  const [printResults, setPrintResults] = useState<Record<string, string>>({});
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const [editingStatusOrderId, setEditingStatusOrderId] = useState<string | null>(null);
  const [statusDropdownPosition, setStatusDropdownPosition] = useState<{ top: number; left: number } | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [updateStatusAfterPrint, setUpdateStatusAfterPrint] = useState(true);
  
  // Print notification states
  const [showPrintNotification, setShowPrintNotification] = useState(false);
  const [printQueueId, setPrintQueueId] = useState<number | undefined>(undefined);
  
  // Filter orders with status "נקלט" by default
  const [filteredOrders, setFilteredOrders] = useState<Order[]>([]);
  const [showAllOrders, setShowAllOrders] = useState(false);
  
  // Helper function to parse dates
  const parseDate = (dateStr: string) => {
    const [day, month, year] = dateStr.split('/');
    return new Date(Number(year), Number(month) - 1, Number(day));
  };
  // Add click outside listener to close dropdown
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        closeStatusDropdown();
      }
    };

    if (editingStatusOrderId) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [editingStatusOrderId]);
  
  useEffect(() => {
    // Filter orders based on status and search term, then sort by date (most recent first)
    const filtered = orders
      .filter(order => 
        (showAllOrders || order.status === 'נקלט') &&
        (searchTerm === '' || 
         order.orderId.includes(searchTerm) || 
         order.customer.toLowerCase().includes(searchTerm.toLowerCase()))
      )
      .sort((a, b) => {
        const dateA = parseDate(a.date);
        const dateB = parseDate(b.date);
        return dateB.getTime() - dateA.getTime(); // Most recent first
      });
    setFilteredOrders(filtered);
  }, [orders, searchTerm, showAllOrders]);
  
  const handleSelectOrder = (orderId: string) => {
    setSelectedOrders(prev => 
      prev.includes(orderId) 
        ? prev.filter(id => id !== orderId) 
        : [...prev, orderId]
    );
  };
  
  const handleSelectAll = () => {
    if (selectedOrders.length === filteredOrders.length) {
      setSelectedOrders([]);
    } else {
      setSelectedOrders(filteredOrders.map(order => order.orderId));
    }
  };
  
  const handlePrintSelected = async () => {
    if (selectedOrders.length === 0) return;
    
    setPrinting(true);
    setPrintResults({});
    try {
      const accessToken = await getAccessTokenSilently();
      const results = await printBatchOrders(selectedOrders, accessToken, updateStatusAfterPrint);
      setPrintResults(results);
      
      // Show notification
      setPrintQueueId(results.queueId);
      setShowPrintNotification(true);
      
      // If updateStatusAfterPrint is true, update the local state to reflect the status change
      if (updateStatusAfterPrint) {
        const updatedOrders = filteredOrders.map(order => {
          if (selectedOrders.includes(order.orderId)) {
            return { ...order, status: 'הודפס' };
          }
          return order;
        });
        setFilteredOrders(updatedOrders);
      }
    } catch (error) {
      console.error('Error printing batch:', error);
    } finally {
      setPrinting(false);
    }
  };
  
  const handleStatusClick = (e: React.MouseEvent, orderId: string) => {
    e.stopPropagation(); // Prevent other click handlers
    
    // Get position for the dropdown
    const target = e.currentTarget as HTMLElement;
    const rect = target.getBoundingClientRect();
    
    setStatusDropdownPosition({
      top: rect.bottom,
      left: rect.left
    });
    
    setEditingStatusOrderId(orderId);
  };
  
  const closeStatusDropdown = () => {
    setEditingStatusOrderId(null);
    setStatusDropdownPosition(null);
  };
  
  const handleStatusChange = async (newStatus: string) => {
    if (!editingStatusOrderId) return;
    
    setUpdatingStatus(true);
    try {
      const accessToken = await getAccessTokenSilently();
      
      // Call API to update status in Google Sheets
      await updateOrderStatus(editingStatusOrderId, newStatus, accessToken);
      
      // Update the order in the local state
      const updatedOrders = filteredOrders.map(order => 
        order.orderId === editingStatusOrderId 
          ? { ...order, status: newStatus } 
          : order
      );
      setFilteredOrders(updatedOrders);
      
      console.log(`Updated order ${editingStatusOrderId} status to ${newStatus}`);
    } catch (error) {
      console.error('Error updating status:', error);
    } finally {
      setUpdatingStatus(false);
      closeStatusDropdown();
    }
  };
  
  const handleModalBackgroundClick = (e: React.MouseEvent<HTMLDivElement>) => {
    // Only close if the click was directly on the background (not on the modal content)
    if (e.target === e.currentTarget) {
      onRequestClose();
    }
  };
  
  return (
    <div className={`batch-print-modal ${isOpen ? 'open' : ''}`} onClick={handleModalBackgroundClick}>
      <div className="modal-content">
        <div className="modal-header">
          <h2>Batch Print Orders</h2>
          <button className="close-button" onClick={onRequestClose}>×</button>
        </div>
        
        <div className="modal-controls">
          <input
            type="text"
            placeholder="Search by ID or customer name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <label className="show-all-checkbox">
            <input
              type="checkbox"
              checked={showAllOrders}
              onChange={() => setShowAllOrders(!showAllOrders)}
            />
            Show all orders (not just "נקלט")
          </label>
          <label className="update-status-checkbox">
            <input
              type="checkbox"
              checked={updateStatusAfterPrint}
              onChange={() => setUpdateStatusAfterPrint(!updateStatusAfterPrint)}
            />
            Update order status to "הודפס" (printed) after printing
          </label>
          <button 
            className="select-all-button"
            onClick={handleSelectAll}
          >
            {selectedOrders.length === filteredOrders.length && filteredOrders.length > 0 ? 'Deselect All' : 'Select All'}
          </button>
          <button 
            className="print-selected-button"
            onClick={handlePrintSelected}
            disabled={selectedOrders.length === 0 || printing}
          >
            {printing ? 'Printing...' : `Print Selected (${selectedOrders.length})`}
          </button>
        </div>
        
        <div className="orders-list">
          <table>
            <thead>
              <tr>
                <th>Select</th>
                <th>Order ID</th>
                <th>Date</th>
                <th>Customer</th>
                <th>Status</th>
                <th>Frame</th>
                <th>Items</th>
                <th>Result</th>
              </tr>
            </thead>
            <tbody>
              {filteredOrders.map(order => (
                <tr key={order.orderId}>
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedOrders.includes(order.orderId)}
                      onChange={() => handleSelectOrder(order.orderId)}
                    />
                  </td>
                  <td>{order.orderId}</td>
                  <td>{order.date}</td>
                  <td>{order.customer}</td>
                  <td 
                    className="status-column"
                    onClick={(e) => handleStatusClick(e, order.orderId)}
                  >
                    <span className={`status-badge status-${order.status}`}>
                      {order.status}
                    </span>
                  </td>
                  <td>{order.frame}</td>
                  <td>{order.items}</td>
                  <td>
                    {printResults[order.orderId] && (
                      <span className={`print-result ${printResults[order.orderId] === 'success' ? 'success' : 'error'}`}>
                        {printResults[order.orderId] === 'success' ? '✓' : '✗'}
                      </span>
                    )}
                  </td>
                </tr>
              ))}
              {filteredOrders.length === 0 && (
                <tr>
                  <td colSpan={8} className="no-orders-message">
                    {showAllOrders ? 'No orders found.' : 'No orders with status "נקלט" found.'}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      
      {/* Status Dropdown */}
      {editingStatusOrderId && statusDropdownPosition && (
        <div 
          ref={dropdownRef}
          style={{
            position: 'fixed',
            top: `${statusDropdownPosition.top}px`,
            left: `${statusDropdownPosition.left}px`,
            zIndex: 1000
          }}
        >
          <StatusDropdown
            orderId={editingStatusOrderId}
            currentStatus={filteredOrders.find(o => o.orderId === editingStatusOrderId)?.status || ''}
            onStatusChange={handleStatusChange}
            onClose={closeStatusDropdown}
          />
        </div>
      )}
      
      {/* Print notification */}
      <PrintNotification
        isVisible={showPrintNotification}
        onClose={() => setShowPrintNotification(false)}
        jobType="batch"
        queueId={printQueueId}
        itemCount={selectedOrders.length}
        autoHideDelay={3000}
      />
    </div>
  );
};

export default BatchPrintModal;
