import React, { useState, useEffect, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import './OrderList.css';
import OrderImagesModal from './OrderImagesModal';
import StatusDropdown from './StatusDropdown';
import FrameTypeDropdown from './FrameTypeDropdown';
import { updateOrderStatus, updateOrderFrameType } from '../../api/googleSheets';

interface Order {
  orderId: string;
  date: string;
  customer: string;
  status: string;
  frame: string;
  items: string;
  phoneNumber: string;
  city: string;
  notes: string;
  price: string;
}

interface OrderListProps {
  orders: Order[];
}

type SortKey = 'orderId' | 'date' | 'items' | 'price';

const OrderList: React.FC<OrderListProps> = ({ orders: initialOrders }) => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [sortConfig, setSortConfig] = useState<{
    key: SortKey;
    direction: 'ascending' | 'descending';
  }>({ key: 'date', direction: 'descending' });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState<string | null>(null);
  const [editingStatusOrderId, setEditingStatusOrderId] = useState<string | null>(null);
  const [statusDropdownPosition, setStatusDropdownPosition] = useState<{ top: number; left: number } | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  
  const [editingFrameTypeOrderId, setEditingFrameTypeOrderId] = useState<string | null>(null);
  const [frameTypeDropdownPosition, setFrameTypeDropdownPosition] = useState<{ top: number; left: number } | null>(null);
  const frameTypeDropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    sortBy('date', 'descending');
  }, [initialOrders]);

  // Add click outside listener to close dropdowns
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        closeStatusDropdown();
      }
    };

    if (editingStatusOrderId) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [editingStatusOrderId]);
  
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (frameTypeDropdownRef.current && !frameTypeDropdownRef.current.contains(event.target as Node)) {
        closeFrameTypeDropdown();
      }
    };

    if (editingFrameTypeOrderId) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [editingFrameTypeOrderId]);

  const sortBy = (key: SortKey, initialDirection?: 'ascending' | 'descending') => {
    let direction: 'ascending' | 'descending' = initialDirection || 'ascending';
    if (!initialDirection && sortConfig.key === key) {
      direction = sortConfig.direction === 'ascending' ? 'descending' : 'ascending';
    }
    setSortConfig({ key, direction });

    const sortedOrders = [...initialOrders].sort((a, b) => {
      if (key === 'date') {
        const dateA = new Date(a[key].split('/').reverse().join('-'));
        const dateB = new Date(b[key].split('/').reverse().join('-'));
        return direction === 'ascending'
          ? dateA.getTime() - dateB.getTime()
          : dateB.getTime() - dateA.getTime();
      } else if (key === 'items' || key === 'price') {
        const valueA = parseFloat(a[key]);
        const valueB = parseFloat(b[key]);
        return direction === 'ascending' ? valueA - valueB : valueB - valueA;
      } else {
        if (a[key] < b[key]) return direction === 'ascending' ? -1 : 1;
        if (a[key] > b[key]) return direction === 'ascending' ? 1 : -1;
        return 0;
      }
    });

    setOrders(sortedOrders);
  };

  const getSortIndicator = (key: SortKey) => {
    if (sortConfig.key !== key) {
      return null;
    }
    return sortConfig.direction === 'ascending' ? '▲' : '▼';
  };

  const getStatusClass = (status: string) => {
    switch (status) {
      case 'מוכן':
        return 'status-ready';
      case 'בוטל':
        return 'status-cancelled';
      case 'הודפס':
        return 'status-printed';
      case 'נקלט':
        return 'status-received';
      default:
        return '';
    }
  };

  const openModal = (orderId: string) => {
    setSelectedOrderId(orderId);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedOrderId(null);
  };

  const handleStatusClick = (e: React.MouseEvent, orderId: string) => {
    e.stopPropagation(); // Prevent opening the order modal
    
    // Get position for the dropdown
    const target = e.currentTarget as HTMLElement;
    const rect = target.getBoundingClientRect();
    
    setStatusDropdownPosition({
      top: rect.bottom,
      left: rect.left
    });
    
    setEditingStatusOrderId(orderId);
  };

  const { getAccessTokenSilently } = useAuth0();

  const handleStatusChange = async (newStatus: string) => {
    if (!editingStatusOrderId) return;
    
    try {
      // Get access token
      const accessToken = await getAccessTokenSilently();
      
      // Call API to update status in Google Sheets
      await updateOrderStatus(editingStatusOrderId, newStatus, accessToken);
      
      // Update the order in the local state
      const updatedOrders = orders.map(order => 
        order.orderId === editingStatusOrderId 
          ? { ...order, status: newStatus } 
          : order
      );
      setOrders(updatedOrders);
      
      console.log(`Updated order ${editingStatusOrderId} status to ${newStatus}`);
    } catch (error) {
      console.error('Error updating status:', error);
    } finally {
      // Close the dropdown
      closeStatusDropdown();
    }
  };

  const closeStatusDropdown = () => {
    setEditingStatusOrderId(null);
    setStatusDropdownPosition(null);
  };
  
  const handleFrameTypeClick = (e: React.MouseEvent, orderId: string) => {
    e.stopPropagation(); // Prevent opening the order modal
    
    // Get position for the dropdown
    const target = e.currentTarget as HTMLElement;
    const rect = target.getBoundingClientRect();
    
    setFrameTypeDropdownPosition({
      top: rect.bottom,
      left: rect.left
    });
    
    setEditingFrameTypeOrderId(orderId);
  };
  
  const handleFrameTypeChange = async (newFrameType: string) => {
    if (!editingFrameTypeOrderId) return;
    
    try {
      // Get access token
      const accessToken = await getAccessTokenSilently();
      
      // Call API to update frame type
      await updateOrderFrameType(editingFrameTypeOrderId, newFrameType, accessToken);
      
      // Update the order in the local state
      const updatedOrders = orders.map(order => 
        order.orderId === editingFrameTypeOrderId 
          ? { ...order, frame: newFrameType } 
          : order
      );
      setOrders(updatedOrders);
      
      console.log(`Updated order ${editingFrameTypeOrderId} frame type to ${newFrameType}`);
    } catch (error) {
      console.error('Error updating frame type:', error);
    } finally {
      // Close the dropdown
      closeFrameTypeDropdown();
    }
  };
  
  const closeFrameTypeDropdown = () => {
    setEditingFrameTypeOrderId(null);
    setFrameTypeDropdownPosition(null);
  };

  return (
    <div className="order-list-container">
      <table className="order-list">
        <thead>
          <tr>
            <th onClick={() => sortBy('orderId')}>
              <span className="header-content">
                Order ID
                <span className="sort-indicator">{getSortIndicator('orderId')}</span>
              </span>
            </th>
            <th onClick={() => sortBy('date')}>
              <span className="header-content">
                Date
                <span className="sort-indicator">{getSortIndicator('date')}</span>
              </span>
            </th>
            <th>Customer</th>
            <th onClick={() => sortBy('price')}>
              <span className="header-content">
                Price
                <span className="sort-indicator">{getSortIndicator('price')}</span>
              </span>
            </th>
            <th>Status</th>
            <th>Frame</th>
            <th onClick={() => sortBy('items')}>
              <span className="header-content">
                #
                <span className="sort-indicator">{getSortIndicator('items')}</span>
              </span>
            </th>
            <th>Phone Number</th>
            <th>City</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => (
            <tr key={order.orderId} onClick={() => openModal(order.orderId)}>
              <td data-label="Order ID" className='orderid-column'>
                <span className="order-id-link">
                  {order.orderId}
                </span>
              </td>
              <td data-label="Date" className='date-column'>{order.date}</td>
              <td data-label="Customer" className='customername-column'>{order.customer}</td>
              <td data-label="Price" className='price-column'>
                {order.price ? `${order.price}₪` : ''}
              </td>              
              <td 
                data-label="Status" 
                className='status-column'
                onClick={(e) => handleStatusClick(e, order.orderId)}
              >
                <span className={`status-badge ${getStatusClass(order.status)}`}>
                  {order.status}
                </span>
              </td>
              <td 
                data-label="Frame" 
                className='frametype-column'
                onClick={(e) => handleFrameTypeClick(e, order.orderId)}
              >
                {order.frame}
              </td>
              <td data-label="Items" className='frameamount-column'>{order.items}</td>
              <td data-label="Phone Number" className='phonenumber-column'>{order.phoneNumber}</td>
              <td data-label="City" className='city-column'>{order.city}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {selectedOrderId && (
        <OrderImagesModal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          orderId={selectedOrderId}
        />
      )}
      
      {/* Status Dropdown */}
      {editingStatusOrderId && statusDropdownPosition && (
        <div 
          ref={dropdownRef}
          style={{
            position: 'fixed',
            top: `${statusDropdownPosition.top}px`,
            left: `${statusDropdownPosition.left}px`,
            zIndex: 1000
          }}
        >
          <StatusDropdown
            orderId={editingStatusOrderId}
            currentStatus={orders.find(o => o.orderId === editingStatusOrderId)?.status || ''}
            onStatusChange={handleStatusChange}
            onClose={closeStatusDropdown}
          />
        </div>
      )}
      
      {/* Frame Type Dropdown */}
      {editingFrameTypeOrderId && frameTypeDropdownPosition && (
        <div 
          ref={frameTypeDropdownRef}
          style={{
            position: 'fixed',
            top: `${frameTypeDropdownPosition.top}px`,
            left: `${frameTypeDropdownPosition.left}px`,
            zIndex: 1000
          }}
        >
          <FrameTypeDropdown
            orderId={editingFrameTypeOrderId}
            currentFrameType={orders.find(o => o.orderId === editingFrameTypeOrderId)?.frame || ''}
            onFrameTypeChange={handleFrameTypeChange}
            onClose={closeFrameTypeDropdown}
          />
        </div>
      )}
    </div>
  );
};

export default OrderList;
