import React, { useState } from 'react';
import Modal from 'react-modal';
import { FrameType, FRAME_PRESETS } from '../../services/printingService';
import './ConfirmationPopup.css';

interface ConfirmationPopupProps {
  isOpen: boolean;
  onRequestClose: () => void;
  selectedImages: {
    [imageName: string]: { frameType: FrameType; isGreyscale: boolean; url: string };
  };
  onConfirm: (printOrderTxt: boolean, updateStatus?: boolean) => void;
  onDeselectImage: (imageName: string) => void;
  onChangeIndividualPreset: (imageName: string, frameType: FrameType) => void;
  onChangeGreyscale: (imageName: string) => void;
  globalFrameType: FrameType | null;
  onChangeGlobalPreset: (frameType: FrameType) => void;
  isPrinting: boolean;
  shouldPrintOrderTxt: boolean;
  setShouldPrintOrderTxt: React.Dispatch<React.SetStateAction<boolean>>;
}

const ConfirmationPopup: React.FC<ConfirmationPopupProps> = ({
  isOpen,
  onRequestClose,
  selectedImages,
  onConfirm,
  onDeselectImage,
  onChangeIndividualPreset,
  onChangeGreyscale,
  globalFrameType,
  onChangeGlobalPreset,
  isPrinting,
  shouldPrintOrderTxt,
  setShouldPrintOrderTxt,
}) => {
  const [updateStatus, setUpdateStatus] = useState(true);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Confirm Print"
      className="confirmation-popup"
    >
      <h3>Confirm Print</h3>
      <div className="global-preset">
        <label>Global Preset: </label>
        <select
          value={globalFrameType || ''}
          onChange={(e) => onChangeGlobalPreset(e.target.value as FrameType)}
        >
          {Object.entries(FRAME_PRESETS).map(([key, value]) => (
            <option key={key} value={key}>
              {value.hebrew} ({key})
            </option>
          ))}
        </select>
      </div>
      <div className="image-list">
        {Object.entries(selectedImages).map(([imageName, { frameType, isGreyscale, url }]) => (
          <div key={imageName} className="image-item">
            <img src={url} alt={imageName} className="image-preview" />
            <span className="image-name">{imageName}</span>
            <div className="image-controls">
              <select
                value={frameType}
                onChange={(e) => onChangeIndividualPreset(imageName, e.target.value as FrameType)}
              >
                {Object.entries(FRAME_PRESETS).map(([key, value]) => (
                  <option key={key} value={key}>
                    {value.hebrew} ({key})
                  </option>
                ))}
              </select>
              <label className="greyscale-checkbox">
                <input
                  type="checkbox"
                  checked={isGreyscale}
                  onChange={() => onChangeGreyscale(imageName)}
                />
                Greyscale
              </label>
              <button className="remove-button" onClick={() => onDeselectImage(imageName)}>
                Remove
              </button>
            </div>
          </div>
        ))}
      </div>
      <div className="confirmation-actions">
        <div className="confirmation-options">
          <label>
            <input
              type="checkbox"
              checked={shouldPrintOrderTxt}
              onChange={(e) => setShouldPrintOrderTxt(e.target.checked)}
            />
            Print order.txt
          </label>
          <label>
            <input
              type="checkbox"
              checked={updateStatus}
              onChange={(e) => setUpdateStatus(e.target.checked)}
            />
            Update order status to "הודפס" (printed)
          </label>
        </div>
        <button 
          className="confirm-button" 
          onClick={() => onConfirm(shouldPrintOrderTxt, updateStatus)} 
          disabled={isPrinting}
        >
          {isPrinting ? 'Printing...' : 'Confirm Print'}
        </button>
        <button className="cancel-button" onClick={onRequestClose} disabled={isPrinting}>
          Cancel
        </button>
      </div>
    </Modal>
  );
};

export default ConfirmationPopup;
