import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { updateOrderStatus } from '../../api/googleSheets';
import './StatusDropdown.css';

interface StatusDropdownProps {
  orderId: string;
  currentStatus: string;
  onStatusChange: (newStatus: string) => void;
  onClose: () => void;
}

const StatusDropdown: React.FC<StatusDropdownProps> = ({
  orderId,
  currentStatus,
  onStatusChange,
  onClose,
}) => {
  const [selectedStatus, setSelectedStatus] = useState<string>(currentStatus);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { getAccessTokenSilently } = useAuth0();

  const statusOptions = ['נקלט', 'הודפס', 'מוכן'];

  const handleStatusSelect = (status: string) => {
    setSelectedStatus(status);
    if (status !== currentStatus) {
      setShowConfirmation(true);
    } else {
      onClose();
    }
  };

  const handleConfirm = async () => {
    setIsUpdating(true);
    setError(null);
    
    try {
      const accessToken = await getAccessTokenSilently();
      await updateOrderStatus(orderId, selectedStatus, accessToken);
      onStatusChange(selectedStatus);
      onClose();
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to update status');
    } finally {
      setIsUpdating(false);
    }
  };

  const handleCancel = () => {
    setShowConfirmation(false);
    onClose();
  };

  if (showConfirmation) {
    return (
      <div className="status-confirmation-dialog">
        <div className="status-confirmation-content">
          <h3>Update Order Status</h3>
          <p>
            Change status from "{currentStatus}" to "{selectedStatus}"?
          </p>
          {error && <div className="status-error">{error}</div>}
          <div className="status-confirmation-actions">
            <button 
              onClick={handleCancel} 
              className="status-cancel-btn"
              disabled={isUpdating}
            >
              Cancel
            </button>
            <button 
              onClick={handleConfirm} 
              className="status-confirm-btn"
              disabled={isUpdating}
            >
              {isUpdating ? 'Updating...' : 'Confirm'}
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="status-dropdown">
      <div className="status-dropdown-content">
        {statusOptions.map((status) => (
          <div
            key={status}
            className={`status-option ${status === currentStatus ? 'current' : ''}`}
            onClick={() => handleStatusSelect(status)}
          >
            {status}
          </div>
        ))}
      </div>
    </div>
  );
};

export default StatusDropdown;
