import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { updateOrderFrameType } from '../../api/googleSheets';
import './FrameTypeDropdown.css';

interface FrameTypeDropdownProps {
  orderId: string;
  currentFrameType: string;
  onFrameTypeChange: (newFrameType: string) => void;
  onClose: () => void;
}

const FrameTypeDropdown: React.FC<FrameTypeDropdownProps> = ({
  orderId,
  currentFrameType,
  onFrameTypeChange,
  onClose,
}) => {
  const [selectedFrameType, setSelectedFrameType] = useState<string>(currentFrameType);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { getAccessTokenSilently } = useAuth0();

  // Frame type options in Hebrew
  const frameTypeOptions = ['קלאסי', 'נועז', 'אותנטי', 'נקי'];

  const handleFrameTypeSelect = (frameType: string) => {
    setSelectedFrameType(frameType);
    if (frameType !== currentFrameType) {
      setShowConfirmation(true);
    } else {
      onClose();
    }
  };

  const handleConfirm = async () => {
    setIsUpdating(true);
    setError(null);
    
    try {
      const accessToken = await getAccessTokenSilently();
      await updateOrderFrameType(orderId, selectedFrameType, accessToken);
      onFrameTypeChange(selectedFrameType);
      onClose();
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to update frame type');
    } finally {
      setIsUpdating(false);
    }
  };

  const handleCancel = () => {
    setShowConfirmation(false);
    onClose();
  };

  if (showConfirmation) {
    return (
      <div className="frame-type-confirmation-dialog">
        <div className="frame-type-confirmation-content">
          <h3>Update Frame Type</h3>
          <p>
            Change frame type from "{currentFrameType}" to "{selectedFrameType}"?
          </p>
          {error && <div className="frame-type-error">{error}</div>}
          <div className="frame-type-confirmation-actions">
            <button 
              onClick={handleCancel} 
              className="frame-type-cancel-btn"
              disabled={isUpdating}
            >
              Cancel
            </button>
            <button 
              onClick={handleConfirm} 
              className="frame-type-confirm-btn"
              disabled={isUpdating}
            >
              {isUpdating ? 'Updating...' : 'Confirm'}
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="frame-type-dropdown">
      <div className="frame-type-dropdown-content">
        {frameTypeOptions.map((frameType) => (
          <div
            key={frameType}
            className={`frame-type-option ${frameType === currentFrameType ? 'current' : ''}`}
            onClick={() => handleFrameTypeSelect(frameType)}
          >
            {frameType}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FrameTypeDropdown;
