// File: src/components/Orders/MobileOrderImagesModal.tsx

import React, { useState, useCallback, useEffect } from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import './MobileOrderImagesModal.css';
import MobileCropModal from './MobileCropModal';
import MobileImagePopupModal from './MobileImagePopupModal';
import MobileConfirmationPopup from './MobileConfirmationPopup';
import PrintNotification, { PrintJobType } from './PrintNotification';
import {
  printOrder,
  FrameType,
  FRAME_PRESETS,
  printMultipleImages,
  printSingleImage,
  printOrderTxt,
} from '../../services/printingService';
import { useAuth0 } from '@auth0/auth0-react';

interface MobileOrderImagesModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  orderId: string;
}

interface ImageData {
  name: string;
  url: string;
}

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';

const MobileOrderImagesModal: React.FC<MobileOrderImagesModalProps> = ({
  isOpen,
  onRequestClose,
  orderId,
}) => {
  const { getAccessTokenSilently } = useAuth0();

  const [images, setImages] = useState<ImageData[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [cropModalOpen, setCropModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [selectedImage, setSelectedImage] = useState<ImageData | null>(null);
  const [isPrinting, setIsPrinting] = useState(false);
  const [isPrintingOrderTxt, setIsPrintingOrderTxt] = useState(false);
  const [shouldPrintOrderTxt, setShouldPrintOrderTxt] = useState(true);
  const [frameType, setFrameType] = useState<FrameType | null>(null);
  const [isSelectingMultiple, setIsSelectingMultiple] = useState(false);
  const [selectedImages, setSelectedImages] = useState<{
    [imageName: string]: { frameType: FrameType; isGreyscale: boolean };
  }>({});
  const [originalFrameType, setOriginalFrameType] = useState<FrameType>('Classic');
  const [isGreyscale, setIsGreyscale] = useState(false);
  const [selectedOrderPreset, setSelectedOrderPreset] = useState<FrameType>('Classic');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedImagesForConfirmation, setSelectedImagesForConfirmation] = useState<{
    [imageName: string]: { frameType: FrameType; isGreyscale: boolean; url: string };
  }>({});
  const [globalFrameType, setGlobalFrameType] = useState<FrameType | null>(null);
  
  // Print notification states
  const [showPrintNotification, setShowPrintNotification] = useState(false);
  const [printNotificationType, setPrintNotificationType] = useState<PrintJobType>('single');
  const [printQueueId, setPrintQueueId] = useState<number | undefined>(undefined);
  const [printItemCount, setPrintItemCount] = useState(1);

  // Fetch images
  const fetchImages = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await axios.get(`${API_BASE_URL}/images/${orderId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setImages(response.data.imageData || []);
    } catch (error) {
      console.error('Error fetching images:', error);
      setError('Failed to fetch images');
    } finally {
      setLoading(false);
    }
  }, [orderId, getAccessTokenSilently]);

  // Fetch frame type
  const fetchFrameType = useCallback(async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await axios.get(`${API_BASE_URL}/images/${orderId}/frame-type`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setFrameType(response.data.frameType);
      setOriginalFrameType(response.data.frameType);
    } catch (error) {
      console.error('Error fetching frame type:', error);
      setError('Failed to fetch frame type');
    }
  }, [orderId, getAccessTokenSilently]);

  useEffect(() => {
    if (isOpen) {
      fetchImages();
      fetchFrameType();
    }
  }, [isOpen, fetchImages, fetchFrameType]);

  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      setCropModalOpen(true);
    }
  };

  const handleCropComplete = async (croppedImageBlob: Blob) => {
    if (!selectedFile) return;

    try {
      const accessToken = await getAccessTokenSilently();
      setUploadProgress(0);
      const formData = new FormData();
      formData.append('image', croppedImageBlob, selectedFile.name);

      await axios.post(`${API_BASE_URL}/images/${orderId}/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${accessToken}`,
        },
        onUploadProgress: (progressEvent) => {
          if (progressEvent.total) {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(percentCompleted);
          }
        },
      });

      setCropModalOpen(false);
      setSelectedFile(null);
      setUploadProgress(0);
      fetchImages();
    } catch (error) {
      console.error('Error uploading cropped image:', error);
      setError('Failed to upload cropped image');
    }
  };

  const handleImageClick = (image: ImageData) => {
    if (isSelectingMultiple) {
      const updatedSelectedImages = { ...selectedImages };
      if (updatedSelectedImages[image.name]) {
        delete updatedSelectedImages[image.name];
      } else {
        updatedSelectedImages[image.name] = {
          frameType: frameType || 'Classic',
          isGreyscale: false,
        };
      }
      setSelectedImages(updatedSelectedImages);
    } else {
      setSelectedImage(image);
      setIsGreyscale(false);
    }
  };

  const handleClosePopup = () => {
    setSelectedImage(null);
  };

  const handleDeleteImage = async () => {
    if (!selectedImage) return;

    try {
      const accessToken = await getAccessTokenSilently();
      await axios.delete(`${API_BASE_URL}/images/${orderId}/${selectedImage.name}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setSelectedImage(null);
      fetchImages();
    } catch (error) {
      console.error('Error deleting image:', error);
      setError('Failed to delete image');
    }
  };

  const handleShowConfirmation = () => {
    const allImages = images.reduce((acc, image) => {
      acc[image.name] = { 
        frameType: frameType || 'Classic', 
        isGreyscale: false,
        url: image.url
      };
      return acc;
    }, {} as { [imageName: string]: { frameType: FrameType; isGreyscale: boolean; url: string } });
    
    setSelectedImagesForConfirmation(allImages);
    setGlobalFrameType(frameType || 'Classic');
    setShowConfirmation(true);
  };

  const handleConfirmPrint = async (shouldPrintOrderTxt: boolean, updateStatus: boolean = false) => {
    setIsPrinting(true);
    try {
      const accessToken = await getAccessTokenSilently();
      
      // Print selected images first
      const imagesToPrint = Object.entries(selectedImagesForConfirmation).map(
        ([imageName, { frameType, isGreyscale }]) => ({
          imageName,
          frameType,
          isGreyscale,
        })
      );
      
      // Print the images
      const imagesResponse = await printMultipleImages(orderId, imagesToPrint, accessToken);
      console.log('Print jobs sent for selected images', imagesResponse);
      
      // Now print the order.txt AFTER the images if requested
      let orderTxtResponse = null;
      if (shouldPrintOrderTxt) {
        orderTxtResponse = await printOrderTxt(orderId, accessToken);
        console.log('Print job sent for order.txt', orderTxtResponse);
      }
      
      // Show notification
      setPrintNotificationType('order');
      setPrintQueueId(imagesResponse.queueIds?.[0]);
      setPrintItemCount(imagesToPrint.length + (shouldPrintOrderTxt ? 1 : 0));
      setShowPrintNotification(true);
      
      // Update order status if requested
      if (updateStatus) {
        try {
          await axios.put(
            `${API_BASE_URL}/orders/${orderId}/status`,
            { status: 'הודפס' },
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
              },
            }
          );
          console.log('Order status updated to "הודפס"');
        } catch (statusError) {
          console.error('Error updating order status:', statusError);
        }
      }
      
      setShowConfirmation(false);
    } catch (error) {
      console.error('Error printing selected images:', error);
      setError('Failed to print selected images');
    } finally {
      setIsPrinting(false);
    }
  };

  const handleDeselectImage = (imageName: string) => {
    const updatedSelection = { ...selectedImagesForConfirmation };
    delete updatedSelection[imageName];
    setSelectedImagesForConfirmation(updatedSelection);
  };

  const handleChangeIndividualPreset = (imageName: string, newFrameType: FrameType) => {
    setSelectedImagesForConfirmation(prev => ({
      ...prev,
      [imageName]: { ...prev[imageName], frameType: newFrameType },
    }));
  };

  const handleChangeGlobalPreset = (newFrameType: FrameType) => {
    setGlobalFrameType(newFrameType);
    setSelectedImagesForConfirmation(prev => 
      Object.entries(prev).reduce((acc, [imageName, imageData]) => {
        acc[imageName] = { ...imageData, frameType: newFrameType };
        return acc;
      }, {} as typeof prev)
    );
  };

  const handleChangeGreyscale = (imageName: string) => {
    setSelectedImagesForConfirmation(prev => ({
      ...prev,
      [imageName]: { ...prev[imageName], isGreyscale: !prev[imageName].isGreyscale },
    }));
  };

  const handlePrintOrderTxt = async () => {
    setIsPrintingOrderTxt(true);
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await printOrderTxt(orderId, accessToken);
      console.log('Print job sent for order.txt', response);
      
      // Show notification
      setPrintNotificationType('order_txt');
      setPrintQueueId(response.queueId);
      setPrintItemCount(1);
      setShowPrintNotification(true);
    } catch (error) {
      console.error('Error printing order.txt:', error);
      setError('Failed to print order.txt');
    } finally {
      setIsPrintingOrderTxt(false);
    }
  };

  const toggleSelectMultiple = () => {
    setIsSelectingMultiple(!isSelectingMultiple);
    setSelectedImages({});
  };

  const handleFrameTypeChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    imageName: string
  ) => {
    event.stopPropagation();
    const newFrameType = event.target.value as FrameType;
    setSelectedImages((prev) => ({
      ...prev,
      [imageName]: {
        ...prev[imageName],
        frameType: newFrameType,
      },
    }));
  };

  const handleGreyscaleChange = (imageName: string) => {
    setSelectedImages((prev) => ({
      ...prev,
      [imageName]: {
        ...prev[imageName],
        isGreyscale: !prev[imageName].isGreyscale,
      },
    }));
  };

  const handlePrintSelectedImages = async () => {
    if (Object.keys(selectedImages).length === 0) {
      alert('No images selected.');
      return;
    }
    setIsPrinting(true);
    try {
      const accessToken = await getAccessTokenSilently();
      const imagesToPrint = Object.entries(selectedImages).map(
        ([imageName, { frameType, isGreyscale }]) => ({
          imageName,
          frameType,
          isGreyscale,
        })
      );
      const response = await printMultipleImages(orderId, imagesToPrint, accessToken);
      console.log('Print jobs sent for selected images', response);
      
      // Show notification
      setPrintNotificationType('order');
      setPrintQueueId(response.queueIds?.[0]);
      setPrintItemCount(imagesToPrint.length);
      setShowPrintNotification(true);
      
      setIsSelectingMultiple(false);
      setSelectedImages({});
    } catch (error) {
      console.error('Error printing selected images:', error);
      setError('Failed to print selected images');
    } finally {
      setIsPrinting(false);
    }
  };

  const handlePrintSingleImage = async () => {
    if (!selectedImage) return;
    
    // Ask if the status should be changed to "הודפס" (printed)
    const updateStatus = window.confirm(
      'Would you like to update the order status to "הודפס" (printed) after printing this image?'
    );
    
    setIsPrinting(true);
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await printSingleImage(
        orderId,
        selectedImage.name,
        frameType || 'Classic',
        isGreyscale,
        accessToken,
        updateStatus
      );
      console.log('Print job sent for image:', selectedImage.name, response);
      
      // Show notification
      setPrintNotificationType('single');
      setPrintQueueId(response.queueId);
      setPrintItemCount(1);
      setShowPrintNotification(true);
      
      if (updateStatus) {
        console.log('Order status updated to "הודפס"');
      }
      setSelectedImage(null);
    } catch (error) {
      console.error('Error printing single image:', error);
      setError('Failed to print image');
    } finally {
      setIsPrinting(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Order Images"
      className="mobile-modal"
      overlayClassName="mobile-modal-overlay"
    >
      <h2>Images for Order {orderId}</h2>
      {frameType && (
        <p className="frame-type-display">
          Original Frame Type: {FRAME_PRESETS[frameType].hebrew} ({frameType})
        </p>
      )}
      <input
        type="file"
        accept="image/*"
        onChange={handleImageUpload}
        disabled={cropModalOpen}
      />
      {loading && <p>Loading images...</p>}
      {error && <p className="error-message">{error}</p>}
      {uploadProgress > 0 && uploadProgress < 100 && <p>Uploading: {uploadProgress}%</p>}

      {!loading && !error && (
        <>
          <button onClick={toggleSelectMultiple} className="mobile-select-multiple-button">
            {isSelectingMultiple ? 'Cancel Selection' : 'Select Multiple Images'}
          </button>

          {isSelectingMultiple && (
            <div className="mobile-multiple-actions">
              <button
                onClick={handlePrintSelectedImages}
                className="mobile-print-selected-button"
                disabled={isPrinting}
              >
                {isPrinting ? 'Printing...' : 'Print Selected Images'}
              </button>
            </div>
          )}

          <div className="mobile-image-grid">
            {images.length > 0 ? (
              images.map((image, index) => (
                <div
                  key={index}
                  className="mobile-image-item"
                  onClick={() => handleImageClick(image)}
                >
                  <img
                    src={image.url}
                    alt={`Order ${orderId} - Image ${index + 1}`}
                    className={selectedImages[image.name] ? 'selected' : ''}
                  />
                  {isSelectingMultiple && (
                    <>
                      <input
                        type="checkbox"
                        checked={!!selectedImages[image.name]}
                        onChange={() => handleImageClick(image)}
                        className="mobile-image-checkbox"
                        onClick={(e) => e.stopPropagation()}
                      />
                      {selectedImages[image.name] && (
                        <>
                          <select
                            value={selectedImages[image.name].frameType}
                            onChange={(e) => handleFrameTypeChange(e, image.name)}
                            className="mobile-image-frame-select"
                            onClick={(e) => e.stopPropagation()}
                          >
                            {Object.entries(FRAME_PRESETS).map(([key, value]) => (
                              <option key={key} value={key}>
                                {value.hebrew} ({key})
                              </option>
                            ))}
                          </select>
                          <label className="greyscale-option">
                            <input
                              type="checkbox"
                              checked={selectedImages[image.name].isGreyscale}
                              onChange={() => handleGreyscaleChange(image.name)}
                              onClick={(e) => e.stopPropagation()}
                            />
                            Greyscale
                          </label>
                        </>
                      )}
                    </>
                  )}
                  <p>{image.name}</p>
                </div>
              ))
            ) : (
              <p>No images found for this order.</p>
            )}
          </div>
        </>
      )}

      <div className="mobile-order-print-controls">
        <select
          value={selectedOrderPreset}
          onChange={(e) => setSelectedOrderPreset(e.target.value as FrameType)}
          className="mobile-order-preset-select"
        >
          {Object.entries(FRAME_PRESETS).map(([key, value]) => (
            <option key={key} value={key}>
              {value.hebrew} ({key})
            </option>
          ))}
        </select>
        <button
          onClick={handleShowConfirmation}
          disabled={isPrinting || loading || !!error}
          className="mobile-print-order-button"
        >
          {isPrinting ? 'Printing...' : 'Print Order'}
        </button>
        <button
          onClick={handlePrintOrderTxt}
          disabled={isPrintingOrderTxt || loading || !!error}
          className="mobile-print-order-txt-button"
        >
          {isPrintingOrderTxt ? 'Printing...' : 'Print Order.txt'}
        </button>
      </div>

      <button onClick={onRequestClose} className="mobile-close-button">
        Close
      </button>

      {cropModalOpen && selectedFile && (
        <MobileCropModal
          file={selectedFile}
          onComplete={handleCropComplete}
          onCancel={() => {
            setCropModalOpen(false);
            setSelectedFile(null);
          }}
        />
      )}

      {selectedImage && (
        <MobileImagePopupModal
          isOpen={!!selectedImage}
          onRequestClose={handleClosePopup}
          imageUrl={selectedImage.url}
          imageName={selectedImage.name}
          onDelete={handleDeleteImage}
          orderId={orderId}
          frameType={frameType || 'Classic'}
          onPrint={handlePrintSingleImage}
          isPrinting={isPrinting}
          setFrameType={setFrameType}
          isGreyscale={isGreyscale}
          setIsGreyscale={setIsGreyscale}
        />
      )}

      <MobileConfirmationPopup
        isOpen={showConfirmation}
        onRequestClose={() => setShowConfirmation(false)}
        selectedImages={selectedImagesForConfirmation}
        onConfirm={handleConfirmPrint}
        onDeselectImage={handleDeselectImage}
        onChangeIndividualPreset={handleChangeIndividualPreset}
        onChangeGreyscale={handleChangeGreyscale}
        globalFrameType={globalFrameType}
        onChangeGlobalPreset={handleChangeGlobalPreset}
        isPrinting={isPrinting}
        shouldPrintOrderTxt={shouldPrintOrderTxt}
        setShouldPrintOrderTxt={setShouldPrintOrderTxt}
      />
      
      {/* Print notification */}
      <PrintNotification
        isVisible={showPrintNotification}
        onClose={() => setShowPrintNotification(false)}
        jobType={printNotificationType}
        queueId={printQueueId}
        itemCount={printItemCount}
        autoHideDelay={3000}
      />
    </Modal>
  );
};

export default MobileOrderImagesModal;
