// File: src/pages/Dashboard.tsx

import React, { useState, useEffect, useMemo } from 'react';
import SalesGraph from '../components/Dashboard/SalesGraph';
import OrderSummary from '../components/Orders/OrderSummary';
import OrderList from '../components/Orders/OrderList';
import CreateOrderModal from '../components/Orders/CreateOrderModal';
import BatchPrintModal from '../components/Orders/BatchPrintModal';
import { fetchOrders } from '../api/googleSheets';
import './Dashboard.css';
import { useAuth0 } from '@auth0/auth0-react';

const Dashboard: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [orders, setOrders] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [filter, setFilter] = useState('All');
  const [searchTerm, setSearchTerm] = useState('');
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isBatchPrintModalOpen, setIsBatchPrintModalOpen] = useState(false);

  const loadOrders = async () => {
    try {
      setLoading(true);
      const accessToken = await getAccessTokenSilently();
      const data = await fetchOrders(accessToken);
      setOrders(data);
    } catch (err: unknown) {
      if (err instanceof Error) {
        setError(`Failed to load orders: ${err.message}`);
        console.error('Error loading orders:', err);
      } else {
        setError('Failed to load orders: An unknown error occurred');
        console.error('Unknown error loading orders:', err);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadOrders();
  }, [getAccessTokenSilently]);

  // Helper function to parse dates
  const parseDate = (dateStr: string) => {
    const [day, month, year] = dateStr.split('/');
    // Ensure padding of single digits with leading zeros
    const paddedMonth = month.padStart(2, '0');
    const paddedDay = day.padStart(2, '0');
    // Create date using a more reliable format
    return new Date(Number(year), Number(month) - 1, Number(day));
  };

  const validOrders = useMemo(
    () =>
      orders
        .filter(order => /^\d+$/.test(order.orderId))
        .map(order => {
          // Parse date
          const dateObject = parseDate(order.date);
          // Parse price and frame count
          const priceNumber = parseFloat(order.price) || 0;
          const frameCount = parseInt(order.items) || 0;

          return {
            ...order,
            dateObject,
            priceNumber,
            frameCount,
          };
        }),
    [orders]
  );

  const statusCounts = useMemo(() => {
    return validOrders.reduce((acc, order) => {
      acc[order.status] = (acc[order.status] || 0) + 1;
      return acc;
    }, {} as Record<string, number>);
  }, [validOrders]);

  const filteredOrders = useMemo(() => {
    return validOrders.filter(order => {
      const matchesStatus = filter === 'All' || order.status === filter;
      const matchesSearch =
        searchTerm === '' ||
        order.orderId.toLowerCase().includes(searchTerm.toLowerCase()) ||
        order.customer.toLowerCase().includes(searchTerm.toLowerCase());
      return matchesStatus && matchesSearch;
    });
  }, [validOrders, filter, searchTerm]);

  // Calculate printed frames counts
  const printedFramesCounts = useMemo(() => {
    const whiteFrames = ['אותנטי', 'נקי'];
    const blackFrames = ['נועז', 'קלאסי'];

    return validOrders.reduce(
      (acc, order) => {
        if (order.status === 'הודפס') {
          if (whiteFrames.includes(order.frame)) {
            acc.white += parseInt(order.items) || 0;
          } else if (blackFrames.includes(order.frame)) {
            acc.black += parseInt(order.items) || 0;
          }
        }
        return acc;
      },
      { white: 0, black: 0 }
    );
  }, [validOrders]);

  // Calculate average daily orders for current month and last month
  const avgDailyOrders = useMemo(() => {
    const today = new Date();
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const daysPassed = Math.max(1, Math.ceil((today.getTime() - startOfMonth.getTime()) / (1000 * 60 * 60 * 24)));
    
    const monthOrders = validOrders.filter(order => 
      order.dateObject >= startOfMonth && order.dateObject <= today
    ).length;

    const currentAvg = Number((monthOrders / daysPassed).toFixed(1));

    // Last month calculation
    const startOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const endOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
    const lastMonthDays = endOfLastMonth.getDate();

    const lastMonthOrders = validOrders.filter(order => 
      order.dateObject >= startOfLastMonth && order.dateObject <= endOfLastMonth
    ).length;

    const lastMonthAvg = Number((lastMonthOrders / lastMonthDays).toFixed(1));

    return { current: currentAvg, previous: lastMonthAvg };
  }, [validOrders]);

  // Calculate average frames per order for current month and last month
  const avgFramesPerOrder = useMemo(() => {
    const today = new Date();
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    
    const monthOrders = validOrders.filter(order => 
      order.dateObject >= startOfMonth && order.dateObject <= today
    );

    const totalFrames = monthOrders.reduce((sum, order) => 
      sum + (parseInt(order.items) || 0), 0
    );

    const currentAvg = monthOrders.length > 0 ? 
      Number((totalFrames / monthOrders.length).toFixed(1)) : 0;

    // Last month calculation
    const startOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const endOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);

    const lastMonthOrders = validOrders.filter(order => 
      order.dateObject >= startOfLastMonth && order.dateObject <= endOfLastMonth
    );

    const lastMonthFrames = lastMonthOrders.reduce((sum, order) => 
      sum + (parseInt(order.items) || 0), 0
    );

    const lastMonthAvg = lastMonthOrders.length > 0 ? 
      Number((lastMonthFrames / lastMonthOrders.length).toFixed(1)) : 0;

    return { current: currentAvg, previous: lastMonthAvg };
  }, [validOrders]);

  // Helper function to check if two dates are the same day
  const isSameDay = (date1: Date, date2: Date) =>
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate();

  // Orders Today and Yesterday
  const ordersToday = useMemo(() => {
    const today = new Date();
    return validOrders.reduce((count, order) => {
      if (isSameDay(order.dateObject, today)) {
        return count + 1;
      }
      return count;
    }, 0);
  }, [validOrders]);

  const ordersYesterday = useMemo(() => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return validOrders.reduce((count, order) => {
      if (isSameDay(order.dateObject, yesterday)) {
        return count + 1;
      }
      return count;
    }, 0);
  }, [validOrders]);

  // Frames Today and Yesterday
  const framesToday = useMemo(() => {
    const today = new Date();
    return validOrders.reduce((count, order) => {
      if (isSameDay(order.dateObject, today)) {
        return count + (parseInt(order.items) || 0);
      }
      return count;
    }, 0);
  }, [validOrders]);

  const framesYesterday = useMemo(() => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return validOrders.reduce((count, order) => {
      if (isSameDay(order.dateObject, yesterday)) {
        return count + (parseInt(order.items) || 0);
      }
      return count;
    }, 0);
  }, [validOrders]);

  // Sales Today and Yesterday
  const todaySales = useMemo(() => {
    const today = new Date();
    return validOrders.reduce((sum, order) => {
      if (isSameDay(order.dateObject, today)) {
        return sum + order.priceNumber;
      }
      return sum;
    }, 0);
  }, [validOrders]);

  const yesterdaySales = useMemo(() => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return validOrders.reduce((sum, order) => {
      if (isSameDay(order.dateObject, yesterday)) {
        return sum + order.priceNumber;
      }
      return sum;
    }, 0);
  }, [validOrders]);

  // Calculate average price per order for current month and last month
  const avgPricePerOrder = useMemo(() => {
    const today = new Date();
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    
    const monthOrders = validOrders.filter(order => 
      order.dateObject >= startOfMonth && order.dateObject <= today
    );

    const totalPrice = monthOrders.reduce((sum, order) => 
      sum + order.priceNumber, 0
    );

    const currentAvg = monthOrders.length > 0 ? 
      Number((totalPrice / monthOrders.length).toFixed(1)) : 0;

    // Last month calculation
    const startOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const endOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);

    const lastMonthOrders = validOrders.filter(order => 
      order.dateObject >= startOfLastMonth && order.dateObject <= endOfLastMonth
    );

    const lastMonthPrice = lastMonthOrders.reduce((sum, order) => 
      sum + order.priceNumber, 0
    );

    const lastMonthAvg = lastMonthOrders.length > 0 ? 
      Number((lastMonthPrice / lastMonthOrders.length).toFixed(1)) : 0;

    return { current: currentAvg, previous: lastMonthAvg };
  }, [validOrders]);

  // Sales This Month and Same Period Last Month
  const salesThisMonth = useMemo(() => {
    const today = new Date();
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

    return validOrders.reduce((sum, order) => {
      if (order.dateObject >= startOfMonth && order.dateObject <= today) {
        return sum + order.priceNumber;
      }
      return sum;
    }, 0);
  }, [validOrders]);

  const salesSamePeriodLastMonth = useMemo(() => {
    const today = new Date();
    const endOfPeriod = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
    const startOfLastMonth = new Date(endOfPeriod.getFullYear(), endOfPeriod.getMonth(), 1);

    // Adjust for months with fewer days
    if (endOfPeriod.getMonth() !== (today.getMonth() + 11) % 12) {
      endOfPeriod.setDate(0); // Last day of previous month
    }

    return validOrders.reduce((sum, order) => {
      if (order.dateObject >= startOfLastMonth && order.dateObject <= endOfPeriod) {
        return sum + order.priceNumber;
      }
      return sum;
    }, 0);
  }, [validOrders]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleCreateOrder = () => {
    setIsCreateModalOpen(true);
  };

  const handleBatchPrint = () => {
    setIsBatchPrintModalOpen(true);
  };

  return (
    <div className="dashboard">
      <div className="summary-cards">
        <OrderSummary
          title="Sales Today"
          count={todaySales}
          previousCount={yesterdaySales}
          icon="💰"
          isCurrency
        />
        <OrderSummary 
          title="Orders Today" 
          count={ordersToday} 
          previousCount={ordersYesterday}
          icon="🛒" 
        />
        <OrderSummary 
          title="Frames Today" 
          count={framesToday}
          previousCount={framesYesterday}
          icon="📦" 
        />
        <OrderSummary 
          title="Avg Daily Orders" 
          count={avgDailyOrders.current}
          previousCount={avgDailyOrders.previous}
          icon="📊"
        />
        <OrderSummary 
          title="Avg Frames/Order" 
          count={avgFramesPerOrder.current}
          previousCount={avgFramesPerOrder.previous}
          icon="📏"
        />
        <OrderSummary
          title="Avg Price/Order"
          count={avgPricePerOrder.current}
          previousCount={avgPricePerOrder.previous}
          icon="💵"
          isCurrency
        />
        <OrderSummary
          title="Sales This Month"
          count={salesThisMonth}
          previousCount={salesSamePeriodLastMonth}
          icon="📈"
          isCurrency
        />
      </div>
      <div className="printed-frames-status">
        <div className="frame-count">
          <span className="frame-label">White Frames (Printed):</span>
          <span className="frame-value">{printedFramesCounts.white}</span>
        </div>
        <div className="frame-count">
          <span className="frame-label">Black Frames (Printed):</span>
          <span className="frame-value">{printedFramesCounts.black}</span>
        </div>
      </div>

      <div className="filter-wrapper">
        <div className="order-controls">
          <button 
            className="create-order-button"
            onClick={handleCreateOrder}
          >
            Create New Order
          </button>
          <button 
            className="batch-print-button"
            onClick={handleBatchPrint}
          >
            Batch Print
          </button>
          <input
            type="text"
            placeholder="חיפוש הזמנה"
            className="filter-input"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>
        <div className="filter-tabs">
          <button
            className={`filter-tab ${filter === 'All' ? 'active' : ''}`}
            onClick={() => setFilter('All')}
          >
            All ({validOrders.length})
          </button>
          <button
            className={`filter-tab ${filter === 'נקלט' ? 'active' : ''}`}
            onClick={() => setFilter('נקלט')}
          >
            נקלט ({statusCounts['נקלט'] || 0})
          </button>
          <button
            className={`filter-tab ${filter === 'הודפס' ? 'active' : ''}`}
            onClick={() => setFilter('הודפס')}
          >
            הודפס ({statusCounts['הודפס'] || 0})
          </button>
          <button
            className={`filter-tab ${filter === 'בוטל' ? 'active' : ''}`}
            onClick={() => setFilter('בוטל')}
          >
            בוטל ({statusCounts['בוטל'] || 0})
          </button>
          <button
            className={`filter-tab ${filter === 'מוכן' ? 'active' : ''}`}
            onClick={() => setFilter('מוכן')}
          >
            מוכן ({statusCounts['מוכן'] || 0})
          </button>
        </div>
      </div>
      <SalesGraph orders={validOrders} />
      <OrderList orders={filteredOrders} />
      
      <CreateOrderModal 
        isOpen={isCreateModalOpen}
        onRequestClose={() => {
          setIsCreateModalOpen(false);
          loadOrders();
        }}
      />
      
      <BatchPrintModal
        isOpen={isBatchPrintModalOpen}
        onRequestClose={() => {
          setIsBatchPrintModalOpen(false);
          loadOrders(); // Reload orders to refresh status after batch printing
        }}
        orders={validOrders}
      />
    </div>
  );
};

export default Dashboard;
