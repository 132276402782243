import React, { useMemo, useState, useEffect, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import './MobileOrderList.css';
import MobileOrderImagesModal from './MobileOrderImagesModal';
import StatusDropdown from './StatusDropdown';
import { updateOrderStatus } from '../../api/googleSheets';

interface Order {
  orderId: string;
  date: string;
  customer: string;
  status: string;
  frame: string;
  items: string;
  phoneNumber: string;
  city: string;
}

interface MobileOrderListProps {
  orders: Order[];
}

const MobileOrderList: React.FC<MobileOrderListProps> = ({ orders: initialOrders }) => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [selectedOrderId, setSelectedOrderId] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingStatusOrderId, setEditingStatusOrderId] = useState<string | null>(null);
  const [statusDropdownPosition, setStatusDropdownPosition] = useState<{ top: number; left: number } | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  
  // Initialize orders from props
  useEffect(() => {
    setOrders(initialOrders);
  }, [initialOrders]);
  
  // Add click outside listener to close dropdown
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        closeStatusDropdown();
      }
    };

    if (editingStatusOrderId) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [editingStatusOrderId]);

  const getStatusClass = (status: string) => {
    switch (status) {
      case 'מוכן':
        return 'status-ready';
      case 'בוטל':
        return 'status-cancelled';
      case 'הודפס':
        return 'status-printed';
      case 'נקלט':
        return 'status-received';
      default:
        return '';
    }
  };

  const sortedOrders = useMemo(() => {
    return [...orders].sort((a, b) => {
      const dateA = new Date(a.date.split('/').reverse().join('-'));
      const dateB = new Date(b.date.split('/').reverse().join('-'));
      return dateB.getTime() - dateA.getTime(); // Descending order
    });
  }, [orders]);

  const openModal = (orderId: string) => {
    setSelectedOrderId(orderId);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedOrderId(null);
  };
  
  const handleStatusClick = (e: React.MouseEvent, orderId: string) => {
    e.stopPropagation(); // Prevent opening the order modal
    
    // Get position for the dropdown
    const target = e.currentTarget as HTMLElement;
    const rect = target.getBoundingClientRect();
    
    setStatusDropdownPosition({
      top: rect.bottom,
      left: rect.left
    });
    
    setEditingStatusOrderId(orderId);
  };

  const { getAccessTokenSilently } = useAuth0();

  const handleStatusChange = async (newStatus: string) => {
    if (!editingStatusOrderId) return;
    
    try {
      // Get access token
      const accessToken = await getAccessTokenSilently();
      
      // Call API to update status in Google Sheets
      await updateOrderStatus(editingStatusOrderId, newStatus, accessToken);
      
      // Update the order in the local state
      const updatedOrders = orders.map(order => 
        order.orderId === editingStatusOrderId 
          ? { ...order, status: newStatus } 
          : order
      );
      setOrders(updatedOrders);
      
      console.log(`Updated order ${editingStatusOrderId} status to ${newStatus}`);
    } catch (error) {
      console.error('Error updating status:', error);
    } finally {
      // Close the dropdown
      closeStatusDropdown();
    }
  };

  const closeStatusDropdown = () => {
    setEditingStatusOrderId(null);
    setStatusDropdownPosition(null);
  };

  return (
    <div className="mobile-order-list" >
      {sortedOrders.map(order => (
        <div key={order.orderId} className="mobile-order-item" onClick={() => openModal(order.orderId)}>
          <div className="mobile-order-header" onClick={() => openModal(order.orderId)}>
            <span className="mobile-order-id">Order #{order.orderId}</span>
            <span 
              className={`mobile-status-badge ${getStatusClass(order.status)}`}
              onClick={(e) => handleStatusClick(e, order.orderId)}
            >
              {order.status}
            </span>
          </div>
          <div className="mobile-order-details">
            <p><strong>Date:</strong> {order.date}</p>
            <p><strong>Customer:</strong> {order.customer}</p>
            <p><strong>Items:</strong> {order.items}</p>
            <p><strong>Frame:</strong> {order.frame}</p>
            <p><strong>Phone:</strong> {order.phoneNumber}</p>
            <p><strong>City:</strong> {order.city}</p>
          </div>
        </div>
      ))}
      {selectedOrderId && (
        <MobileOrderImagesModal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          orderId={selectedOrderId}
        />
      )}
      
      {/* Status Dropdown */}
      {editingStatusOrderId && statusDropdownPosition && (
        <div 
          ref={dropdownRef}
          style={{
            position: 'fixed',
            top: `${statusDropdownPosition.top}px`,
            left: `${statusDropdownPosition.left}px`,
            zIndex: 1000
          }}
        >
          <StatusDropdown
            orderId={editingStatusOrderId}
            currentStatus={orders.find(o => o.orderId === editingStatusOrderId)?.status || ''}
            onStatusChange={handleStatusChange}
            onClose={closeStatusDropdown}
          />
        </div>
      )}
    </div>
  );
};

export default MobileOrderList;
